.admin-section_image-container {
    position: relative;

    .admin-section_image {
        width: 100%;
    }

    .admin-section_title {
        position: absolute;
        color: white;
        font-size: 2rem;
        top: 50%;
        left: 50%;
        transform: translate(-50% , -50%);
    }
}


.product--container {
    margin-bottom: 25px;
    .form-container{
        display: flex;
        flex-direction: column;
        width: 20%;
        .create_product--section{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-direction: column;
            
            input, select, textarea{
                background-color: #eeeeee;
                font-weight: 400;
                color: #5f5f5f;
                width: 95%;
                border: none;
                border-radius: 15px;
                height: 33px;
                margin: 10px;
                padding: 0px 0px 0px 8px;
                font-family: "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
                &:focus{
                    outline: none;
                }
            }
            textarea {
                padding-top: 5px;
                height: auto;
            }
            input[type="file"]{
                display: none;
            }
            .variant__form{
                display: flex;
                flex-direction: column;
                align-items: center;
                h5{
                    margin-top: 0;
                }
                .variants__items{
                    display: flex;
                    flex-wrap: wrap;
                    span {
                        display: flex;
                        cursor: pointer;
                        align-items: center;
                        margin-right: 5px;
                        background-color: rgb(158, 158, 173);
                        border-radius: 10px;
                        padding: 5px;
                        color: white;
                        svg{
                            margin-left: 5px;
                        }
                    }
                }
            }
            .create_product--section_label {
                background-color: rgb(238, 238, 238);
                height: 2rem;
                width: 13rem;
                color: #545454;
                border-radius: 15px;
                font-size: 14px;
                text-align: center;
                margin-bottom: 5%;
                align-items: center;
                display: flex;
                justify-content: space-around;
                cursor: pointer;
            }
            .imagesContainer {
                .image {
                    position: relative;
                    padding-right: 10px;
                    padding-top: 10px;
                    svg {
                        position: absolute;
                        visibility: hidden;
                        top: 0;
                        right: 0;
                        cursor: pointer;
                    }
                    &:hover {
                        svg {
                            visibility:visible ;
                        }
                    }
                }
            }
        }
    }
    .button {
        background-color: #418fde;
        color: #f4f5f5;
        border:none;
        border-radius: 12px;
        padding: 7px 23px;
        cursor: pointer;
        outline: none;
        height: auto;
        margin-top: 10px
    }
}

//admin banner
@media only screen and (max-width: 768px) {
    .admin-section_image-container .admin-section_title {
        text-align: center;
        font-size: 1.3rem;
        width: 90%;
        top: 30%;
    }
}