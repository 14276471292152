.login-section {
    background-image: url("https://image.freepik.com/free-vector/white-abstract-background-design_23-2148825582.jpg");
    height: 100vh;
    position: relative;

    .login-container-info {
        width: 30%; 
        display: flex; 
        text-align: center; 
        justify-content: center; 
        border-radius: 12px; 
        padding: 2% 4%; 
        flex-direction: column; 
        background-color: #dddddd;
        color: #3d3d3d;
        position: absolute;
        align-items: center;
        top: 50%;
        left: 50%;
        transform: translate(-50% , -50%);

        .login-input {
            background-color: #ffffff;
            font-weight: 400;
            color: #3f3f3f;
            width: 95%;
            border: none;
            border-radius: 15px;
            height: 33px;
            margin: 10px;
            padding: 0px 0px 0px 8px;
            &:focus{
                outline: none;
            }
        }

        .login-button {
            background-color: #418fde;
            color: #f4f5f5;
            border:none;
            border-radius: 12px;
            padding: 7px;
            cursor: pointer;
            outline: none;
            height: auto;
            margin-top: 10px;
            width: 90%;
        }
    }
}

@media only screen and (max-width: 500px) {
    .login-section {
        .login-container-info {
            width: 65%;
            font-size: 12px;
            height: 55%;
            top: 40%;
        }
    }
}

@media only screen and (min-width: 1024px) {
    .login-section {
        .login-container-info {
            width: 33%;
            height: 55%;
        }
    }
}