.homeContainer {
  width: 100%;
}

.slider {
  width: 100%;
  overflow: hidden;
}

.slider ul {
  display: flex;
  padding: 0;
  width: 400%;
  animation: cambio 40s infinite;
  animation-direction: alternate ;
  animation-timing-function: ease-in;
}

.slider li {
  width: 100%;
  list-style: none;
}

.slider img {
  width: 100%;
}

@keyframes cambio{
0% {margin-left: 0;}
20% {margin-left: 0;}

25% {margin-left: -100%;}
45% {margin-left: -100%;}

50% {margin-left: -200%;}
70% {margin-left: -200%;}

75% {margin-left: -300%;}
100% {margin-left: -300%;}
}